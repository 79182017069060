
.loader-wrapper {
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;

  .logo {
    z-index: 2;
    position: absolute;
    width: 90%;
    height: 90%;
    border-radius: 50%;
    background-image: url("../images/logo.png");
    background-repeat: no-repeat;
    background-size: contain;
    top: 10px;
  }

  .loader {
    z-index: 1;
    position: relative;
    width: 90%;
    height: 90%;
    border-radius: 50%;
    background: linear-gradient(#f07e6e, #84cdfa, #5ad1cd);
    animation: animate 1.2s linear infinite;

    span {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: linear-gradient(#f07e6e, #84cdfa, #5ad1cd);

      &:nth-child(1) {
        filter: blur(5px);
      }

      &:nth-child(2) {
        filter: blur(10px);
      }

      &:nth-child(3) {
        filter: blur(25px);
      }

      &:nth-child(4) {
        filter: blur(50px);
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: 10px;
      left: 10px;
      right: 10px;
      bottom: 10px;
      //background: rgba(84, 128, 161, 1);
      //border: solid rgba(84, 128, 161, 1) 10px;
      border-radius: 50%;
    }
  }

  @keyframes animate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

}